import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import Table, { useColumns } from "@/components/Tables/Vehicles";
import {useAuth} from "@/services/Auth";
import * as moment from "moment";
import { Helmet } from "react-helmet";
import * as csv from 'csv-stringify/sync';
import FileSaver from 'file-saver';
import Filters from "./components/Filters";

function Vehicles() {
    const history = useHistory();
    const route = useLocation();

    let auth = useAuth();

    const {t} = useTranslation();
    const columns = useColumns();
    const exportColumns = useColumns(null, true);

    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);

    const [query, setQuery] = useState(route?.state?.query || {});

    const exportVehicles = () => {
        let headers = exportColumns.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.skip = 0;
        exportRequest.limit = 0;

        setExporting(true);

        auth.getRequest('/vehicles', exportRequest)
            .then(response => {

                const records = response.data.vehicles.map((record) => {
                    return exportColumns.map(_ => {
                        var value = record,
                        paths = _.dataField.split('.'),
                        name;

                        while((name = paths.shift()) && value)
                        {
                            value = value[name];
                        }

                       return _.exportFormatter ? _.exportFormatter(value, record) : value;
                    });
                });

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'vehicles.csv', {});

                setExporting(false);
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }

    // TODO filter for issues
    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.vehicles')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column">
                <h4 className="text-primary">{t('drawer_link_titles.vehicles')}</h4>

                <div className="d-flex align-items-center">
                    {
                        auth?.roles?.create_vehicle &&
                        <Link to={`/vehicles/import`} className="btn btn-primary mx-1 my-1 my-md-0">{t('import_vehicles')}</Link>
                    }
                    {
                        !exporting ?
                            <Button variant="success" className="mx-md-1 my-1" onClick={() => exportVehicles()}>{t('export')} (.csv)</Button> :
                            <Button variant="success" className="mx-md-1 my-1" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="mx-2">{t('please_wait')}</span>
                            </Button>
                    }
                    {
                        auth?.roles?.create_vehicle &&
                        <Link to="/vehicles/create" className="btn btn-secondary mx-1  my-1 my-md-0">{t('new_vehicle')}</Link>
                    }
                </div>

            </Card.Subtitle>

            <Filters searching={loading || exporting} values={query} onSubmit={setQuery} />

            <Table query={query} showPagination={true} onData={_ => setLoading(!_)} />
        </Card>
    );
}

export default Vehicles;
