import {useTranslation}                               from "react-i18next";
import {useAuth}                                      from "@/services/Auth";
import {Link, useParams}                              from "react-router-dom";
import React, {useState, useEffect}                   from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import LoadingButton                                  from "@/components/LoadingButton";
import { Helmet }                                     from "react-helmet";
import LoadingPage                                    from "@/components/LoadingPage";
import * as csv                                       from "csv-stringify/sync";
import FileSaver                                      from "file-saver";
import ImportUploadForm                               from "@/components/Forms/ImportUploadForm";

function ImportPayments() {
    const {t, i18n: i18n} = useTranslation();
    let auth = useAuth();
    let {id} = useParams();
    const importEndpoint = 'payments/import';
    const getRequest = auth.getRequest;

    const [success, setSuccess] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [importSchema, setImportSchema] = useState(null);

    const downloadSampleFile = () => {
        let headers = importSchema.map(column => t(column));
        setDownloadingFile(true);
        getRequest('payments/import-sample')
            .then(response => {
                const records = response.data.sample_records;

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'payments.csv', {});
                setDownloadingFile(false);
                setDownloadingFile(false);
            })
            .catch(error => {

                setDownloadingFile(false);
            })
    }

    useEffect(() => {
        if(importSchema === null)
        {
            let request = getRequest(importEndpoint);

            request
                .then(response => {
                    setImportSchema(Object.keys(response.data.schema));
                })
                .catch(error => {
                })

            return () => request?.abort && request.abort();

        }
    }, [importSchema]);

    return (
        <div>
            <Helmet>
                <title>{t('import_payments')} / {t('drawer_link_titles.clients')} - {t('app')}</title>
            </Helmet>
            {
                success &&
                <div className="d-flex justify-content-center align-items-center" style={{minHeight: 400}}>
                    <Card border="light">
                        <Card.Body className="text-center">
                            <i className="bi bi-check-circle" style={{fontSize: "10rem"}}/>
                            <Card.Title>{t('payments_imported')}</Card.Title>
                            <Card.Text>
                                {t('all_payments_are_imported')}
                            </Card.Text>
                            <div className="d-grid gap-2">
                                <Link variant="primary" className="btn btn-primary" to={`/clients`}>{t('go_to_clients')}</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                !success &&
                <Card className="mx-2 my-2 p-2">

                    <Card.Body className="px-2">
                        <Row>
                            <Col className="col-12 mb-4">
                                <h5>{t('choose_csv_file_to_upload')}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 mb-4 d-flex justify-content-between">
                                <h6>{t('csv_file_should_contain_the_following_columns')}</h6>
                                <LoadingButton loading={downloadingFile} variant="success" titleTranslationKey="download_sample_file" onSubmit={() => downloadSampleFile()}/>
                            </Col>
                            <Col className="col-12 mb-4">
                                {
                                    importSchema && (<Table striped bordered hover className="mt-1">
                                        <thead>
                                        <tr>
                                            <th>{t('column')}</th>
                                            <th>{t('example')}</th>
                                            <th>{t('notes')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            importSchema.includes('account_number') &&
                                            <tr>
                                                <td>{t('account_number')}</td>
                                                <td>123456789</td>
                                                <td>Required</td>
                                            </tr>
                                        }
                                        {
                                            importSchema.includes('order_id') &&
                                            <tr>
                                                <td>{t('order_id')}</td>
                                                <td>112233</td>
                                                <td>Required</td>
                                            </tr>
                                        }
                                        {
                                            importSchema.includes('payment_date') &&
                                            <tr>
                                                <td>{t('payment_date')}</td>
                                                <td>28/03/2021</td>
                                                <td>Required</td>
                                            </tr>
                                        }
                                        {
                                            importSchema.includes('currency') &&
                                            <tr>
                                                <td>{t('currency')}</td>
                                                <td>GBP</td>
                                                <td>Required</td>
                                            </tr>
                                        }
                                        {
                                            importSchema.includes('amount') &&
                                            <tr>
                                                <td>{t('amount')}</td>
                                                <td>234.00</td>
                                                <td>Required</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </Table>)
                                    || <LoadingPage/>
                                }
                            </Col>

                        </Row>
                        <ImportUploadForm
                            importSchema={importSchema}
                            endpoint={importEndpoint}
                            onSuccess={()=>{setSuccess(true)}}
                        ></ImportUploadForm>
                    </Card.Body>
                </Card>
            }
        </div>
    )

}

export default ImportPayments;
