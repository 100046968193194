import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import {Link, useParams, useHistory, useLocation} from "react-router-dom";
import LoadingPage from "@/components/LoadingPage";
import {useTranslation} from "react-i18next";
import Filters from "./components/UsersFilters";
import Table, {useColumns} from "@/components/Tables/Users";
import * as csv from 'csv-stringify/sync';
import FileSaver from 'file-saver';
import { Helmet } from "react-helmet";

function Users() {
    const history = useHistory();
    const route = useLocation();

    const {t, i18n} = useTranslation();
    let auth = useAuth();
    const columns = useColumns();

    const [searching, setSearching] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [fileName, setFileName] = useState(null);

    const [query, setQuery] = useState(route.state && route.state.query || {page: 0, skip: 0, limit: 15, include: ['fleets', 'company', 'supplier']});

    const {type, id} = useParams();

    const { interval, setIntervalData } = useState();

    let url = '/users';

    switch(type)
    {
        case 'client':
        case 'clients':
            url = `/clients/${id}${url}`;
        break;

        case 'fleet':
        case 'fleets':
            url = `/fleets/${id}${url}`;
        break;

        case 'supplier':
        case 'suppliers':
            url = `/suppliers/${id}${url}`;
        break;

        case 'site':
        case 'sites':
            url = `/sites/${id}${url}`;
        break;
    }

    const exportUsers = () => {
        let headers = columns.map(_ => _.text);

        let exportRequest = { ...query };

        exportRequest.skip = 0;
        exportRequest.take = 0;
        exportRequest.export = 1;
        exportRequest.columns = columns.map(_ => _.dataField);
        exportRequest.file_headers = headers;

        setExporting(true);

        auth.getRequest(url, exportRequest)
            .then(response => {
                if( response.data.file_name ) {
                    setFileName(response.data.file_name);
                    getExportFile(response.data.file_name);
                }
                else{
                    setExporting(false);
                }
            })
            .catch(error => {
                console.error(error);

                setExporting(false);
            })
    }

    const getExportFile = (file_name) => {
        let retries = 1;
        let interval = setInterval(async () => {
            try {
                let response = await  auth.getRequest(url, {file_name, export: 1})

                if(  response.data !== '' ){
                    clearInterval(interval);
                    downloadExportFile(response, file_name);
                }

                if(retries === 10){
                    clearInterval(interval);
                    setExporting(false);
                }
                retries++;
            }
            catch(error) {
                console.error(error);

                setExporting(false);
            }

        }, 5000);
    }

    function downloadExportFile(response, fileName){
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;

        document.body.appendChild(link);
        link.click();

        setExporting(false);

        document.body.removeChild(link);
    }

    useEffect(() => {
        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )
    }, [query]);

    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('drawer_link_titles.users')} - {t('app')}</title>
            </Helmet>

            <Card.Subtitle>
                <Row>
                    <Col cols="12" className="d-flex justify-content-start justify-content-md-between flex-md-row flex-column">
                        <h4 className="text-primary">{t('find_user')}</h4>
                        <div className="d-flex flex-md-row flex-column">
                            {
                                (!auth.user.is_site_manager && !auth.user.is_client_operator && !auth.user.is_supplier_operator && !auth.user.is_platform_operator) &&
                                <Link to={`/users/import`} className="btn btn-primary nmx-md-1 my-1">{t('import_users')}</Link>
                            }
                            {
                                !exporting ?
                                    <Button variant="success" className="mx-md-1 my-1" onClick={() => exportUsers()}>{t('export')} (.csv)</Button> :
                                    <Button variant="success" className="mx-md-1 my-1" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="mx-2">{t('please_wait')}</span>
                                    </Button>
                            }
                            {
                                (!auth.user.is_client_operator && !auth.user.is_supplier_operator && !auth.user.is_platform_operator) &&
                                <Link to="/users/create" className="btn btn-secondary mx-md-1 my-1">{t('new_user')}</Link>
                            }
                        </div>
                    </Col>
                </Row>
            </Card.Subtitle>

            <Filters
                searching={searching}
                values={query}
                onSubmit={setQuery}
                managing={true}
            />

            <Table url={url} query={query} showPagination={true} onData={_ => setSearching(!_)} />
        </Card>
    );
}

export default Users;
