import {Controller, useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {Button, Form, Col, Row, Spinner}   from "react-bootstrap";
import React, {useEffect, useState}        from "react";
import * as Yup                            from "yup";
import {useTranslation}                    from "react-i18next";
import Select from "react-select";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const formatRecord = (record) => {
    const  object = {...record};

    object.value = record.id;
    object.label = record.name;

    return object;
};

function ClientForm(
    {
        onSubmit,
        accountStatusOptions,
        supportedCurrency,
        paymentTerms,
        accountTypeOptions,
        client,
        countries,
        processingSubmit,
        buttonText,
        serverErrors,
    }) {
    const {t, i18n} = useTranslation();

    const schema = Yup.object().shape({
        name                      : Yup.string()
                                      .required(t('form_validation.name_is_required')),
        primary_contact           : Yup.string()
                                      .required(t('form_validation.primary_contact_name_is_required')),
        email                     : Yup.string().email()
                                      .required(t('form_validation.email_address_is_required')),
        phone_number              : Yup.string()
                                      .required(t('form_validation.phone_number_is_required')),
        account_number            : Yup.string()
                                      .required(t('form_validation.account_number_is_required')),
        account_status            : Yup.string()
                                      .required(t('form_validation.account_status_is_required')),
        account_type              : Yup.string()
                                      .required(t('form_validation.account_type_is_required')),
        currency                  : Yup.string()
                                      .required(t('form_validation.currency_is_required')),
        client_payment_terms      : Yup.string()
                                        .when("account_type", {
                                            is: 'Credit',
                                            then: Yup.string().required(t('form_validation.client_payment_terms_required'))
                                                .typeError(t('form_validation.is_required', { attribute: t('client_payment_terms') }))
                                                .nullable()
                                        }).nullable(),
        credit_limit              : Yup.number()
                                        .when("account_type", {
                                            is: 'Credit',
                                            then: Yup.number().required(t('form_validation.client_credit_limit_required'))
                                                .typeError(t('form_validation.is_required', { attribute: t('credit_limit') }))
                                                .nullable()
                                        }).transform(v => v === '' || isNaN(v) ? null : v).nullable(),
        client_payment_terms_days: Yup.number()
                                        .when("account_type", {
                                            is: 'Credit',
                                            then: Yup.number().required(t('form_validation.client_payment_terms_days_required'))
                                                .typeError(t('form_validation.is_required', { attribute: t('client_payment_terms_days') }))
                                                .nullable()
                                        }).transform(v => v === '' || isNaN(v) ? null : v).nullable(),
        address                  : Yup.string()
                                      .required(t('form_validation.address_is_required')),
        county                   : Yup.string()
                                      .required(t('form_validation.county_is_required')),
        city                     : Yup.string()
                                      .required(t('form_validation.city_town_is_required')),
        country_id               : Yup.number()
                                      .required(t('form_validation.country_is_required')),
        post_code                : Yup.string()
                                      .required(t('form_validation.post_code_is_required')),
    });

    countries = countries.map(formatRecord);


    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setError,
        watch,
        setValue,
        getValues
    } = useForm({
        defaultValues: (_ => {
            const values = {...client};

            if(values.country)
            {
                values.selected_country= values.country;
            }

            if(values.billing_country_select)
            {
                values.selected_billing_country = values.billing_country_select;
            }

            if(!values.hasOwnProperty('billing_address_same_as_registered_address'))
            {
                values.billing_address_same_as_registered_address = true;
            }

            if(values.credit)
            {
                values.credit_limit = values.credit.limit
            }

            return values;
        })(),
        resolver: yupResolver(schema),
    });

    useEffect(()=>{
        register('country_id');
        register('selected_country');
        register('billing_country_id');
        register('selected_billing_country');
    })

    useEffect(()=>{
        for(const key of Object.keys(serverErrors))
        {
            setError(key, {
                type: "manual",
                message: serverErrors[key][0]
            },{shouldFocus: true});
        }
    },[serverErrors])

    const accountType   = watch('account_type');
    const billingSameAsRegisteredAddress   = watch('billing_address_same_as_registered_address');
    const country   = watch('selected_country');
    const billing_country   = watch('selected_billing_country');

    const onSave = (values) => {
        if (values.phone_number) {
            let cleanedPhoneNumber = values.phone_number.replace(/['"\s]/g, '');
            values.phone_number = cleanedPhoneNumber.startsWith('+') 
                ? cleanedPhoneNumber 
                : '+' + cleanedPhoneNumber;
        }
        onSubmit(values)
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSave)}>
            <Row>
                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('name')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('enter_name')}
                        {...register('name')}
                        isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.name && errors.name.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('primary_contact')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('primary_contact')}
                        {...register('primary_contact')}
                        isInvalid={!!errors.primary_contact}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.primary_contact && errors.primary_contact.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('email_address')}
                    </Form.Label>

                    <Form.Control
                        type="email"
                        placeholder={t('email_address')}
                        {...register('email')}
                        isInvalid={!!errors.email}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.email && errors.email.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('phone_number')}
                    </Form.Label>

                    <Controller
                        control={control}
                        {...register('phone_number')}
                        isInvalid={!!errors.phone_number}
                        render={({field: { ref, ...field }}) => (
                            <PhoneInput
                                country={'gb'}
                                {...field}
                                inputExtraProps={{
                                    ref
                                }}
                                inputClass={`${!!errors.phone_number ? 'is-invalid': ''}`}
                                inputStyle={{
                                    padding: '0.55rem 14px 0.55rem 60px',
                                    width: 'inherit'
                                }}
                                placeholder={t('phone_number')}
                            />
                        )}
                    />

                    <Form.Control
                        type="hidden"
                        isInvalid={!!errors.phone_number}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.phone_number && errors.phone_number.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('account_number')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('account_number')}
                        {...register('account_number')}
                        isInvalid={!!errors.account_number}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.account_number && errors.account_number.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('account_status')}
                    </Form.Label>

                    <Form.Select
                        {...register('account_status')}
                        placeholder={t('account_status')}
                        isInvalid={!!errors.primary_role}
                    >
                        <option value="">{t('choose')}</option>
                        {
                            accountStatusOptions.map((accountStatusOption) => <option key={accountStatusOption.id} value={accountStatusOption.id}>{t(accountStatusOption.name)}</option>)
                        }
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        {errors.account_status && errors.account_status.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('billing_currency')}
                    </Form.Label>

                    <Form.Select
                        {...register('currency')}
                        placeholder={t('billing_currency')}
                        isInvalid={!!errors.billing_currency}
                    >
                        <option value="">{t('choose')}</option>
                        {
                            supportedCurrency.map((currency) => <option key={currency}>{t(currency)}</option>)
                        }
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        {errors.currency && errors.currency.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('account_type')}
                    </Form.Label>

                    <Form.Select
                        {...register('account_type')}
                        placeholder={t('account_type')}
                        isInvalid={!!errors.account_type}
                    >
                        <option value="">{t('choose')}</option>
                        {
                            accountTypeOptions.map((accountTypeOption) => <option key={accountTypeOption}>{t(accountTypeOption)}</option>)
                        }

                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        {errors.account_type && errors.account_type.message}
                    </Form.Control.Feedback>
                </Col>

                {
                    accountType === 'Credit' &&
                    <>
                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('credit_limit')}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                min={0}
                                placeholder={t('credit_zero_definition',{definition: t('unlimited')})}
                                {...register('credit_limit')}
                                isInvalid={!!errors.credit_limit}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.credit_limit && errors.credit_limit.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('client_payment_terms')}
                            </Form.Label>

                            <Form.Select
                                {...register('client_payment_terms')}
                                placeholder={t('client_payment_terms')}
                                isInvalid={!!errors.client_payment_terms}
                            >
                                <option value="">{t('choose')}</option>
                                {
                                    paymentTerms.map((paymentTerm) => <option key={paymentTerm}>{t(paymentTerm)}</option>)
                                }
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.client_payment_terms && errors.client_payment_terms.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('client_payment_terms_days')}
                            </Form.Label>

                            <Form.Select
                                {...register('client_payment_terms_days')}
                                placeholder={t('client_payment_terms_days')}
                                isInvalid={!!errors.client_payment_terms_days}
                            >
                                <option value="">{t('choose')}</option>
                                <option value={7}>7</option>
                                <option value={14}>14</option>
                                <option value={30}>30</option>
                                <option value={45}>45</option>
                                <option value={60}>60</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.client_payment_terms_days && errors.client_payment_terms_days.message}
                            </Form.Control.Feedback>
                        </Col>
                    </>
                }
            </Row>

            <Row className='mt-4'>
                <h5>{t('registered_address')}</h5>
            </Row>

            <Row>
                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('country')}
                    </Form.Label>

                    <Select
                        className={`react-select ${errors.country_id ? 'is-invalid' : ''}`}
                        isClearable={true}
                        value={country}
                        onChange={(e) => {
                            setValue('country_id', e && e.value );
                            setValue('selected_country', e);
                        }}
                        placeholder={t('country')}
                        options={countries}/>


                    <Form.Control.Feedback type="invalid">
                        {errors.country_id && errors.country_id.message}
                    </Form.Control.Feedback>
                </Col>
            </Row>

            <Row>
                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('first_line_address')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('first_line_address')}
                        {...register('address')}
                        isInvalid={!!errors.address}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.address && errors.address.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('second_line_address')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('second_line_address')}
                        {...register('address_line_2')}
                        isInvalid={!!errors.address_line_2}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.address_line_2 && errors.address_line_2.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('county')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('county')}
                        {...register('county')}
                        isInvalid={!!errors.county}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.county && errors.county.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('city')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('city')}
                        {...register('city')}
                        isInvalid={!!errors.city}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.city && errors.city.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={3} sm={6}>
                    <Form.Label className="my-2">
                        {t('post_code')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('post_code')}
                        {...register('post_code')}
                        isInvalid={!!errors.post_code}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.post_code && errors.post_code.message}
                    </Form.Control.Feedback>
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col>
                    <div className="form-check d-flex">
                        <Form.Check
                            type="checkbox"
                            {...register('billing_address_same_as_registered_address')}
                            label={t('billing_address_same_as_registered_address')}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.billing_address_same_as_registered_address && errors.billing_address_same_as_registered_address.message}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>

            {
                !billingSameAsRegisteredAddress &&
                <>
                    <Row className="mt-4">
                        <h5>{t('billing_address')}</h5>
                    </Row>

                    <Row>
                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('billing_county')}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                placeholder={t('billing_county')}
                                {...register('billing_county')}
                                isInvalid={!!errors.billing_county}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.billing_county && errors.billing_county.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('first_line_billing_address')}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                placeholder={t('first_line_billing_address')}
                                {...register('address_billing')}
                                isInvalid={!!errors.address_billing}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.address_billing && errors.address_billing.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('billing_address_line_2')}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                placeholder={t('billing_address_line_2')}
                                {...register('billing_address_line_2')}
                                isInvalid={!!errors.billing_address_line_2}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.billing_address_line_2 && errors.billing_address_line_2.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('country')}
                            </Form.Label>

                            <Select
                                className={`react-select ${errors.country_id ? 'is-invalid' : ''}`}
                                isClearable={true}
                                value={billing_country}
                                onChange={(e) => {
                                    setValue('billing_country_id', e && e.value);
                                    setValue('selected_billing_country', e);
                                }}
                                placeholder={t('country')}
                                options={countries}/>

                            <Form.Control.Feedback type="invalid">
                                {errors.billing_country_id && errors.billing_country_id.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('city')}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                placeholder={t('city')}
                                {...register('billing_city')}
                                isInvalid={!!errors.billing_city}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.billing_city && errors.billing_city.message}
                            </Form.Control.Feedback>
                        </Col>

                        <Col md={3} sm={6}>
                            <Form.Label className="my-2">
                                {t('post_code')}
                            </Form.Label>

                            <Form.Control
                                type="text"
                                placeholder={t('post_code')}
                                {...register('post_code_billing')}
                                isInvalid={!!errors.post_code_billing}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.post_code_billing && errors.post_code_billing.message}
                            </Form.Control.Feedback>
                        </Col>

                    </Row>
                </>
            }
            {
                (serverErrors.length !== 0) &&
                <div className="form-group mt-4">
                    {
                        Object.values(serverErrors).map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error[0]}</p>)
                    }
                </div>
            }

            <div className="form-group mt-4">
                <div className="d-grid gap-2">
                    {!processingSubmit ?
                        <Button type="submit" variant="primary">{t(buttonText)}</Button>
                        :
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="mx-2">{t('please_wait')}</span>
                        </Button>
                    }
                </div>
            </div>
        </Form>
    )
}

export default ClientForm;
