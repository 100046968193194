import React, {useEffect, useState, useRef} from "react";
import {Card, Button, Spinner, Table, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuth} from "@/services/Auth";
import SuccessDialog from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";
import * as csv from 'csv-stringify/sync';
import FileSaver from 'file-saver';
import Paginate from "@/components/Paginate";
import Filters from "@/components/Forms/Search";
import { Countries, ServiceCategories }          from "@/services";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ConfirmDialog from "../../../components/Modals/ConformDialog";

function VatRates() {
    const history = useHistory();
    const route = useLocation();

    const {t} = useTranslation();
    let auth = useAuth();

    const requestStore = useRef({});
    const { current: requests } = requestStore;


    const perPage = 15;

    const [edit, setEdit] = useState(false);

    const [exporting, setExporting] = useState(false);

    const [totalRecords, setTotal] = useState(false);
    const [vat_rates, setVatRates] = useState([]);

    const [query, setQuery] = useState(route?.state?.query || {});

    const page = (query?.skip || 0) / perPage;

    const getVatRates = (query) => {
        requests?.vat_rates?.abort && requests.vat_rates.abort();

        setVatRates(false);

        const critera = {...query};

        critera.take = perPage;

        requests.vat_rates = auth.getRequest('/vat-rates', critera)

        requests.vat_rates
           .then(response => {
               setVatRates(response.data.vat_rates);
               setTotal(response.data.total);
           })
    };

    const [showDialog, setShowDialog] = useState(false);
    const [dialogType, setDialogType] = useState('SUCCESS');
    const [dialogMessage, setDialogMessage] = useState('');
    const [downloadingFile, setDownloadingFile] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteVatId, setDeleteVatId] = useState(null);


    const changePage = ({ selected }) => {
        const critera = { ... query };

        critera.skip = selected * perPage;

        setQuery(critera);
    };

    useEffect(() => {
        getVatRates(query);

        history.replace(
            route.pathname,
            {
                ...route.state,

                query,
            }
        )

        return _ => requests?.vat_rates?.abort && requests.vat_rates.abort();
    }, [query]);

    const handleDialogClose = () => {
        setShowDialog(false);
    }

    const handleDeleteDialogClose = () => {
        setShowDeleteConfirmation(false);
    }


    const deleteRecord = () => {
        if(!deleteVatId)
        {
            return;
        }

        auth.deleteRequest(`/vat-rates/${deleteVatId}`)
            .then(response => {
                const records = [].concat(vat_rates);

                const index = records.findIndex(_ => _.id == deleteVatId);

                if(index >= 0)
                {
                    records.splice(index,1);
                }

                setVatRates(records);

            })
            .catch(error => {
                if (error.response.status === 422) {
                    console.log(error.response.data.errors)
                }
            })
            .finally(_ => {
                setShowDeleteConfirmation(false);
                setDeleteVatId(null);
            });
    };



    const VatRate = ({ vat_rate, edit }) => {

        const [countries, setCountries] = useState(null);
        const [categories, setCategories] = useState(null);
        const [saving, setSaving] = useState(false);

        const schema = yup.object().shape({
            country_id: vat_rate.id === 'new' && yup.string().required(t('form_validation.is_required', { attribute: t('country') })) || yup.string().nullable(),

            state_code:  vat_rate.id === 'new' && yup.string().max(6, t('form_validation.max_length', { attribute: t('state_code'), value: 6 })) || yup.string().nullable(),

            category_id: yup.string().nullable(),

            rate: yup.number().min(0, t('form_validation.min', { attribute: t('vat_rate'), value: 0 })),
        });

        const {
            handleSubmit,
            register,
            formState: { errors },
            setError,
        } = useForm({
            defaultValues: vat_rate,
            resolver: yupResolver(schema),
        });

        const onSubmit = (values) => {
            if(saving)
            {
                return;
            }

            setSaving(true);
            const attributes = {...vat_rate, ...values};

            auth.postRequest('/vat-rates', attributes)
                .then(response => {
                    const record = response.data.vat_rate;
                    const records = [].concat(vat_rates);

                    const index = records.findIndex(_ => _.country_id == record.country_id && _.state_code == record.state_code && _.category_id == record.category_id);

                    if(index >= 0)
                    {
                        records[index] = record;
                    }
                    else
                    {
                        records.unshift(record);
                    }

                    setEdit(false);
                    setVatRates(records);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        for (const key in error.response.data.errors) {
                            setError(key, { message: error.response.data.errors[key][0] } );
                        }
                    }
                })
                .finally(_ => setSaving(false));
        };

        const formatRecord = (company) => {
            const  object = {...company};

            object.value = company.id;
            object.label = company.name;

            return object;
        };

        const deleteVatRecord = (id) => {
            setShowDeleteConfirmation(true);
            setDeleteVatId(id);
        }

        useEffect(() => {
            if (countries === null && edit === vat_rate.id) {
                Countries.get()
                    .then(countries => setCountries(countries.map(formatRecord)))
                    .catch(_ => setCountries([]));
            }
        }, [countries, edit]);

        useEffect(() => {
            if (categories === null && edit === vat_rate.id) {
                ServiceCategories.get()
                    .then(categories => setCategories([{id: '', name: ''}].concat(categories.map(formatRecord))))
                    .catch(_ => setCategories([]));
            }
        }, [categories, edit]);

        console.log(errors);

        return (
            <tr>
                <td onClick={_ => edit !== vat_rate.id && setEdit(vat_rate.id)}>
                    {edit === vat_rate.id && vat_rate.id === 'new' && (
                        <>
                        <Form.Select {...register('country_id')}  isInvalid={!!errors.country_id}>
                            {countries?.map && countries.map(country => (
                                <option value={country.id}>{country.name}</option>
                            )) || (
                                <option value="">{t('loading')}</option>
                            )}


                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.country_id && errors.country_id.message}
                        </Form.Control.Feedback>

                        </>
                    ) || vat_rate?.country?.name}
                </td>

                <td onClick={_ => edit !== vat_rate.id && setEdit(vat_rate.id)}>
                    {edit === vat_rate.id && vat_rate.id === 'new' && (
                        <>
                        <Form.Control maxlength="6" {...register('state_code')} isInvalid={!!errors.state_code} placeholder={t('state_code_placeholder')} />


                        <Form.Control.Feedback type="invalid">
                            {errors.state_code && errors.state_code.message}
                        </Form.Control.Feedback>

                        </>
                    ) || vat_rate?.state_code}
                </td>

                <td onClick={_ => edit !== vat_rate.id && setEdit(vat_rate.id)}>
                    {edit === vat_rate.id && vat_rate.id === 'new' && (
                        <>
                        <Form.Select {...register('category_id')}  isInvalid={!!errors.category_id}>
                            {categories?.map && categories.map(category => (
                                <option value={category.id}>{category.name}</option>
                            )) || (
                                <option value="">{t('loading')}</option>
                            )}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.category_id && errors.category_id.message}
                        </Form.Control.Feedback>

                        </>
                    ) || vat_rate?.category?.name}
                </td>

                <td onClick={_ => edit !== vat_rate.id && setEdit(vat_rate.id)}>
                    {edit === vat_rate.id && (
                        <>
                        <Form.Control {...register('rate')} type="number" min="0" isInvalid={!!errors.rate} />

                        <Form.Control.Feedback type="invalid">
                            {errors.rate && errors.rate.message}
                        </Form.Control.Feedback>
                        </>
                    ) || vat_rate?.rate?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </td>

                <td>
                    {edit === vat_rate.id && (
                        <>
                        <Button size="sm" variant="success" onClick={handleSubmit(onSubmit)}>
                            {saving && t('saving') || t('save')}
                        </Button>

                        <Button size="sm" variant="danger" onClick={() => setEdit(false)}>
                            {t('cancel')}
                        </Button>
                    </>) ||
                        <Button variant="light" className="btn-icon btn-rounded" size="sm" onClick={() => deleteVatRecord(vat_rate.id)}>
                            <i className="bi bi-x text-danger"></i>
                        </Button>}
                </td>
            </tr>
        )
    };

    const records = vat_rates?.map && [].concat(vat_rates) || vat_rates;

    if(edit === 'new' && records?.map)
    {
        records.unshift({id: 'new'})
    }



    return (
        <Card className="mx-2 my-2 p-2">
            <Helmet>
                <title>{t('vat_rates')} - {t('app')}</title>
            </Helmet>

            <ConfirmDialog
                message='delete_selected_vat_record'
                cancel='conform_dialog.cancel'
                accept='conform_dialog.delete'
                acceptAction={deleteRecord}
                show={showDeleteConfirmation}
                handleClose={handleDeleteDialogClose}
            />

            <Card.Subtitle className="d-flex justify-content-start justify-content-md-between flex-md-row flex-column">
                <h4 className="text-primary">{t('vat_rates')}</h4>
                <div className="d-flex  flex-md-row flex-column">
                    {auth?.roles?.create_vat_rate &&
                        <Button variant="secondary" onClick={() => setEdit('new')}>{t('new_vat_rate')}</Button>
                    }
                </div>
            </Card.Subtitle>

            <Filters
                searching={!vat_rates || exporting}
                values={query}
                onSubmit={setQuery}
                />

            <Table striped responsive hover >
                <thead>
                    <tr>
                        <th>{t('country')}</th>

                        <th>{t('state_code')}</th>

                        <th>{t('category')}</th>

                        <th>{t('vat_rate')}</th>

                        <th />
                    </tr>
                </thead>

                <tbody>

                    {records?.map && records.map(vat_rate => (
                        <VatRate key={vat_rate.id} {...{vat_rate, edit}} />
                    )) || records?.length === 0 && (
                        <tr>
                            <td colspan="100%">
                                {t('no_data')}
                            </td>
                        </tr>
                    ) || (
                        <tr>
                            <td colspan="100%">
                                {t('loading')}
                            </td>
                        </tr>
                    )}

                </tbody>

            </Table>


            {totalRecords && vat_rates && <Paginate {...{ changePage, totalRecords, page, perPage }} /> || ''}
        </Card>
    );
}

export default VatRates;
