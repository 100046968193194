import React, {useState} from "react";

import {Badge, Button, Card} from 'react-bootstrap';
import {useTranslation}      from "react-i18next";
import {Link}                from "react-router-dom";
import OpenHours             from "./OpenHours";
import FacilitiesList        from "./FacilitiesList";
import {useAuth}             from "@/services/Auth";
import ToggleFavourite from "../../sites/ToggleFavourite";
import api from "@/services/Api";


function SiteMapCard({site, view, updateResponse, width}) {
    let auth = useAuth();
    let baseUrl = api.defaults.baseURL;

    const {t, i18n} = useTranslation();
    const putRequest = auth.putRequest;

    return (
        <Card style={{width: width, height: '100%'}} className="card-shadow">
            <div className="card-body relative p-2">
                <div className="d-flex justify-content-between align-items-center">
                    <SiteNameDistance site={site}/>
                    {
                        site.profile_image?.url ?
                        <Card.Img style={{width: '80px'}} src={site.profile_image.url}/>
                                                :
                        <i className="bi bi-truck" style={{fontSize: "3.8rem"}}></i>
                    }
                </div>
                <div className="card-text">
                    <h3>{site.price_per_twelve_hours}</h3>
                    <h6>
                        {site.latest_promotion &&
                            <Badge className="my-1 text-wrap text-start p-2" bg="success">{site.latest_promotion.message}</Badge>
                        }
                    </h6>
                    <OpenHours facilities={site.facilities}/>

                    <div className="d-flex align-items-center mb-3">
                        <img className="address-icon" src={`/images/address-icon.svg`}/>
                        <span className="mx-1 facility-name-site-card">{site.address}</span>
                    </div>

                    {site.spaces_available && (

                        <div className="d-flex align-items-center mb-3">
                            <span className="mx-1 facility-name-site-card">{t('access_control.site_spaces_available', { spaces: site.spaces_available } )}</span>
                        </div>
                    ) || ''}

                    <FacilitiesList facilities={[].concat(
                        site.services
                          .filter(_ => _.category)
                          .map(_ => _.category)
                        )
                          .concat(
                            site.facilities
                          )} prebookable={site.prebookable} type="FACILITIES"/>
                    <FacilitiesList facilities={site.security_facilities} type="SECURITY"/>
                </div>
            </div>
            <Card.Footer className="p-2">
                <div className="d-grid gap-2">
                    {site.latitude && site.longitude && (
                        <a className="btn btn-success" href={`https://google.com/maps/dir/?api=1&destination=${site.latitude},${site.longitude}`} target="_blank">
                            {t('get_directions')}
                        </a>
                    )}

                    <Link className="btn btn-primary" to={{ pathname: `/bookings/site-card/${site.id}`, state: {site} }}>{t('view_details')}</Link>
                    <ToggleFavourite site={site} updateResponse={updateResponse}/>
                </div>
            </Card.Footer>
        </Card>
    )
};

function SiteNameDistance({site}) {
    const {t, i18n} = useTranslation();

    return (
        <div className="site-name-distance">
            <h5 className="text-primary"> {site.name}</h5>
            {
                (site.journey) &&
                <p className="mb-1 text-black-50">{site.journey.distance.text} - {site.journey.duration.text}</p>
            }

            {typeof site.bearing == typeof 1.2 && typeof site.direction == typeof 8 && !isNaN(site.bearing) && (
                <div>
                    <div style={{
                        display: 'inline-block',
                        transform: `rotate(${site.bearing - 90}deg)`}}>&gt;</div>
                    {(() => {
                        switch(site.direction)
                        {
                            case 0: return t('north_short');
                            case 1: return t('north_west_short');
                            case 2: return t('west_short');
                            case 3: return t('south_west_short');
                            case 4: return t('south_short');
                            case 5: return t('south_east_short');
                            case 6: return t('east_short');
                            case 7: return t('north_east_short');
                        }
                    })()}
                </div>
            ) || ''}
        </div>
    )
}

export default SiteMapCard;
