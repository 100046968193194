import React, { useState, useEffect }  from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services/Auth";


function AccessControlLanes({ site }) {
  let auth = useAuth();
  const { t } = useTranslation();

  const [currentSite, setCurrentSite] = useState();

  useEffect(() => {
    setCurrentSite(site);
  }, [site]);

  const toggleLane = (lane) => {

    auth.putRequest(`sites/${currentSite.id}/set-lane`, {
      lane_id: lane.id,
      enabled: !lane.enabled,
    })
    .then(response => {
      setCurrentSite(response.data.data);
    })
  };


  if(!currentSite?.access_lanes || !currentSite?.access_controlled)
  {
    return <></>;
  }

  const lanes = {};

  currentSite.access_lanes.forEach(lane => {
    const key = 'k' + lane.barrier_id;
    lanes[key] = lanes[key] || { "In": null, "Out": null };

    lanes[key][lane.direction] = lane;
  });

  return (
    <div>

      {site.spaces_available && (
        <h4>
          {t('access_control.site_spaces_available', { spaces: site.spaces_available } )}
        </h4>
      ) || ''}


      <h4>{t('access_control.title')}</h4>

      <h5>{t('access_control.lanes')}</h5>

      <Table bordered className="w-auto">
        <tbody>
          <tr>
            {Object.entries(lanes).map(([index, lane]) => (
                <td key={index}>
                  {lane["Out"] && (
                    <Button variant={lane["Out"].enabled  ? 'danger' : 'link'} title={t('access_control.Out')} onClick={_ => toggleLane(lane["Out"])}>
                      <span className="bi bi-arrow-down" />
                    </Button>
                  ) || ''}
                </td>
            ))}
          </tr>

          <tr>
            {Object.entries(lanes).map(([index, lane]) => (
                <td key={index}>
                  {lane["In"] && (
                    <Button variant={lane["In"].enabled  ? 'success' : 'link'} title={t('access_control.In')} onClick={_ => toggleLane(lane["In"])}>
                      <span className="bi bi-arrow-up" />
                    </Button>
                  ) || ''}
                </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default AccessControlLanes;
