import Sites          from "../../views/pages/sites/Sites";
import Marketing      from "../../views/pages/sites/Marketing";
import Options from "../../views/pages/sites/MarketingOptions";
import SiteSingle     from "../../views/pages/sites/SiteSingle";
import SiteEdit       from "../../views/pages/sites/SiteEdit";
import SiteCreate     from "../../views/pages/sites/SiteCreate";
import SiteDirectory from "../../views/pages/sites/SiteDirectory";
import SitePage     from "../../views/pages/sites/SitePage";
import SitePageEdit     from "../../views/pages/sites/SitePageEdit";
import SiteShowQRCode from "../../views/pages/sites/SiteShowQRCode";
import ReportsIcon    from "../../components/icons/ReportsIcon";
import SitesIcon      from "../../components/icons/SitesIcon";
import ImportSites    from "../../views/pages/sites/ImportSites";
import ClientPricing  from "../../views/pages/sites/ClientPricing";
import PromotionsIcon   from "../../components/icons/PromotionsIcon";
import ExitBans   from "../../views/pages/exit-banning/ExitBans";
import BarrierOpens   from "../../views/pages/barrier-opens/BarrierOpens";
import RegistrationScans   from "../../views/pages/registration-scans/RegistrationScans";

const sitesRoutes = [

    {
        path         : "/sites",
        component    : Sites,
        exact        : true,
        gate         : 'view_sites',
        title        : 'Sites',
        translate_key: 'sites',
        // icon         : 'bi-geo-alt',
        icon         : <SitesIcon/>,
        main         : true,
    },

    {
        path         : "/marketing",
        component    : Marketing,
        exact        : true,
        gate         : 'view_sites',
        translate_key: 'marketing',
        // icon         : 'bi-geo-alt',
        icon         : <PromotionsIcon />,
        main         : true,
    },


    {
        path     : "/sites/options",
        component: Options,
        gate     : 'update_options',
        exact    : true,
    },

    {
        path     : "/sites/create",
        component: SiteCreate,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/sites/import",
        component: ImportSites,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/sites/client-pricing",
        component: ClientPricing,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/suppliers/:supplier/sites",
        component: Sites,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/suppliers/:supplier/sites/create",
        component: SiteCreate,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/sites/edit/:id",
        component: SiteEdit,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/sites/qr-code/:id",
        component: SiteShowQRCode,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },

    {
        path     : "/sites/:id/page/edit",
        component: SitePageEdit,
        gate     : 'view_sites',
        exact    : true,
        main     : false,
    },
    {
        path     : "/pages/:slug",
        component: SitePage,
        exact    : true,
        main     : false,
        public   : true,
    },

    {
        path     : "/directory",
        component: SiteDirectory,
        exact    : true,
        main     : false,
        public   : true,
    },

    {
        path     : "/exit-banning",
        component: ExitBans,
        exact    : true,
        gate     : 'view_exit_banning',
        translate_key: 'exit_banning',
        icon: <i className="bi bi-ban" />,
        main     : true,
    },

    {
        path     : "/barrier-opens",
        component: BarrierOpens,
        exact    : true,
        gate     : 'view_barrier_opens',
        translate_key: 'barrier_opens',
        icon: <i className="bi bi-door-open" />,
        main     : true,
    },

    {
        path     : "/registration-scans",
        component: RegistrationScans,
        exact    : true,
        gate     : 'view_registration_scans',
        translate_key: 'registration_scans',
        icon: <i className="bi bi-camera-video" />,
        main     : true,
    },


    {
        path     : "/sites/:id",
        component: SiteSingle,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },
    {
        path     : "/clients/:client/pricing",
        component: ClientPricing,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },
    {
        path     : "/sites/:site/pricing",
        component: ClientPricing,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },
    {
        path     : "/clients/:client/sites/:site/pricing",
        component: ClientPricing,
        exact    : true,
        gate     : 'view_sites',
        main     : false,
    },
]

export default sitesRoutes;
