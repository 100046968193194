import {useTranslation}                                          from "react-i18next";
import {useAuth}                                                 from "@/services/Auth";
import {Link, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState}                              from "react";
import LoadingPage                                               from "@/components/LoadingPage";
import {Badge, Button, Card, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import Select                                                    from "react-select";
import SuccessDialog                                             from "../../../components/Modals/SuccessDialog";
import { Helmet } from "react-helmet";
import { AsyncPaginate } from 'react-select-async-paginate';

function VehicleCreate(props) {
    const route = useLocation();

    const {t, i18n} = useTranslation();
    let auth = useAuth();

    let {fleet_id} = useParams();

    const [loading, setLoading] = useState(true);
    const [storingVehicle, setStoringVehicle] = useState(false);
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [drivers, setDrivers] = useState([]);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(() => route.state?.driver ?? '');
    const [client, setClient] = useState('');
    const [fleets, setFleets] = useState([]);
    const [clients, setClients] = useState([]);
    const [fleet, setFleet] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);

    const [createSuccess, setCreateSuccess] = useState(false);
    const [createdVehicle, setCreatedVehicle] = useState('');
    const [serverErrors, setServerErrors] = useState([]);
    const [fetchingFleets, setFetchingFleets] = useState(true);

    useEffect(() => {
        getRequiredItems();
    }, []);

    useEffect(() => {
        if (availableDrivers.length > 0 && selectedDriver) {
            setDrivers(availableDrivers.filter(a => a.id === selectedDriver));
        }
    }, [availableDrivers])

    const loadPaginatedClient = async (search, loadedOptions, { page }) => {
      const pageSize = 15;
      const { data } = await auth.getRequest('/clients',
        {
          take: pageSize,
          skip: (page-1)*pageSize,
          search
        });

      const hasMore = page * pageSize < data.total

      return {
        options: data.clients.map(client => {
          return {value: client.id, label: client.name}
        }),
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    }

    const loadFleets = async (search, loadedOptions, { page }) => {
        const pageSize = 15;
        const { data } = await auth.getRequest('/fleets',
        {
            company_id:client?.value,
            skip: (page-1)*pageSize,
            source: 'list',
            search
        });
        const hasMore = page * pageSize < data.total

      return {
        options: data.fleets.map(fleet => {
            return {value: fleet.id, label: fleet.name, driver_setup: fleet.driver_setup}
        }),
          hasMore,
          additional: {
              page: page + 1,
          },
      };
    }

    const getRequiredItems = () => {

        auth.getRequest('/users/drivers')
            .then(response => {
                setLoading(false);
                setAvailableDrivers(response.data.drivers);
            })
            .catch(error => {
                setLoading(false);
            });

        auth.getRequest('/vehicles/types')
            .then(response => {
                setVehicleTypes(response.data);
            })
            .catch(error => {
                setLoading(false);
            });

        if (auth.user.is_platform_admin) {


            auth.getRequest('/clients')
                .then(response => {
                    setClients(response.data.clients);
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                });
        }
    };

    const onSubmit = () => {
        let payload = {
            'registration_number': registrationNumber,
            'vehicle_type'       : vehicleType,
            'fleet_id'           : fleet_id != null ? fleet_id : (fleet && typeof fleet === 'object') ? fleet.value : null,
            'drivers'            : drivers.length !== 0 ? drivers.map(driver => driver.id) : null,
        };
        setServerErrors([])
        setStoringVehicle(true);
        auth.postRequest('/vehicles', payload)
            .then(response => {
                setStoringVehicle(false);
                if (response.data.message && response.data.message === "OK") {
                    setCreateSuccess(true);
                    setCreatedVehicle(response.data.vehicle_id);
                }
            })
            .catch(error => {
                setStoringVehicle(false);

                if (error.response.status === 422) {
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
    };

    const addDrivers = (driverId) => {
        setSelectedDriver(driverId);
        const driver = availableDrivers.find(driver => driver.id == driverId);

        if (driver && !drivers.find(driver => driver.id == driverId)) {
            let driversUpdated = drivers;
            driversUpdated.push(driver);
            setDrivers(driversUpdated)
        }
    };

    const removeDriver = (driverId) => {
        let driversUpdated = drivers.filter(driver => driver.id !== driverId);
        setDrivers(driversUpdated);
    };

    const driverExists = (driverId) => {
        let driver = drivers.find(driver => driver.id === driverId);

        return false;

        return driver != null || driver !== undefined;
    };

    const fleetList = () => {
        let fleetList = fleets;
        if (auth.user.is_platform_admin && client !== '' && typeof client === 'object') {
            fleetList = fleetList.filter(fleet => fleet.company_id == client?.value);
        }

        return fleetList;
    }

    const availableDriversList = () => {
        let availableDriversList = availableDrivers;
        if (auth.user.is_platform_admin && client !== '' && typeof client === 'object') {
            availableDriversList = availableDriversList.filter(driver => driver.companies.includes(client?.value));
        }

        return availableDriversList;
    }

    const handleClose = () => {
        setCreateSuccess(false)
        if (createdVehicle !== '' && createdVehicle != null) {
            props.history.push(`/vehicles/${createdVehicle}`)
        }
    }

    useEffect(() => {
        if( auth.user.is_platform_admin ){
            setFleet([]);
        }
    }, [client]);


    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('create')} / {t('drawer_link_titles.vehicles')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={createSuccess}
                    message="success_dialog.vehicle_created"
                    handleClose={handleClose}
                    RenderButton={() => {
                        return (
                            <>
                                {!auth?.user?.is_driver && (
                                    <Link className="btn btn-primary mx-1 my-md-0 my-2" to={{
                                        pathname: '/bookings/create',
                                        state   : {
                                            ...route.state,
                                            vehicle: createdVehicle
                                        }
                                    }}>
                                        {t('okay')}
                                    </Link>
                                )}
                            </>

                        )
                    }}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h4 className="text-primary">{t('new_vehicle')}</h4>
                </Card.Subtitle>
                <Form className="mt-5">
                    <Row className="">
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    {t('vehicle_registration_number')}
                                </Form.Label>
                                <Col className="col-12">
                                    <Form.Control type="text" placeholder={t('vehicle_registration_number')} value={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)}/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={3} sm={4} className="col-6">
                            <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                                <Form.Label column className="col-12">
                                    {t('vehicle_type')}
                                </Form.Label>
                                <Col className="col-12">
                                    <Form.Select className="mx-1 react-select" value={vehicleType} onChange={e => setVehicleType(e.target.value)}>
                                        <option value="">--{t('choose_type')}--</option>
                                        {vehicleTypes && Object.entries(vehicleTypes).map(([index, value]) => <option value={index} key={index}>{t(`vehicle_types.${value}`)} </option>) || ''}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                        {
                            auth.user.is_platform_admin &&
                            <Col md={3} sm={4} className="col-6">
                                <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                                    <Form.Label column className="col-12">
                                        {t('client')}
                                    </Form.Label>
                                    <Col className="col-12">
                                      <AsyncPaginate
                                        className="react-select"
                                        placeholder={t('choose_client')}
                                        isClearable={true}
                                        value={client}
                                        loadOptions={loadPaginatedClient}
                                        onChange={setClient}
                                        additional={{
                                          page: 1,
                                        }}
                                      />
                                    </Col>
                                </Form.Group>
                            </Col>
                        }
                        {
                            fleet_id == null &&

                            <Col md={3} sm={4} className="col-6">
                                <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                                    <Form.Label column className="col-12">
                                        {t('fleet_name')}
                                    </Form.Label>
                                    <Col className="col-12">
                                        <AsyncPaginate
                                          key={client?.value}
                                          className="react-select"
                                          placeholder={t('choose_fleet')}
                                          isClearable={true}
                                          value={fleet}
                                          onChange={(e) => {
                                              if (e !== null) {
                                                  setFleet(e)
                                              } else {
                                                  setFleet([])
                                              }
                                          }}
                                          loadOptions={loadFleets}
                                          additional={{
                                              page: 1,
                                          }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        }

                        {
                            !Array.isArray(fleet) && fleet.driver_setup !== 'DriverLess' &&
                            <Col md={3} sm={4} className="col-6">
                                <Form.Group as={Row} className="mb-5" controlId="formPlaintextName">
                                    <Form.Label column className="col-12">
                                        {t('add_drivers')}
                                    </Form.Label>
                                    <Col className="col-12">
                                        <Form.Select className="mx-1 react-select" value={selectedDriver}
                                                     onChange={e => addDrivers(e.target.value)}>
                                            <option>--{t('choose_driver')}--</option>
                                            {availableDriversList().map(driver => {
                                                return drivers.find(setDriver => setDriver.id == driver.id) ? null : (
                                                    <option value={driver.id} key={driver.id}>{driver.name} </option>)
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Col>
                        }
                    </Row>

                    {
                        !Array.isArray(fleet) && fleet.driver_setup !== 'DriverLess' &&
                        <Row className="">
                            <Col className="col-12">
                                <Form.Group as={Row} className="mb-5" controlId="formPlaintextCompanyName">
                                    <Form.Label column className="col-12">
                                        {t('drivers')}
                                    </Form.Label>
                                    <Col className="col-12">
                                        {drivers.map(driver =>
                                            <Button variant="light" onClick={() => removeDriver(driver.id)}
                                                    key={driver.id} className="mx-1">
                                                <span className=" me-2"> {driver.name}</span>
                                                <i className="bi bi-x btn-icon"></i>
                                            </Button>
                                        )}
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    <Row className="">
                        <Col className="col-12">
                            {
                                (serverErrors.length !== 0) &&
                                <div className="form-group mt-4">
                                    {
                                        serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error}</p>)
                                    }
                                </div>
                            }
                        </Col>
                    </Row>
                    <div className="d-grid gap-2">
                        {!storingVehicle ?
                         <Button onClick={onSubmit} type="submit"
                                 variant="primary">{t('save_new_vehicle')}</Button>
                                         :
                         <Button variant="primary" disabled>
                             <Spinner
                                 as="span"
                                 animation="border"
                                 size="sm"
                                 role="status"
                                 aria-hidden="true"
                             />
                             <span className="mx-2">{t('please_wait')}</span>
                         </Button>
                        }
                    </div>
                </Form>
            </Card>
        )
    )
}

export default VehicleCreate;
