import React, { useEffect, useState } from "react";
import Table from "@/components/Tables/BarrierOpens";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row, Form, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "@/services/Auth";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import * as momentTimezone               from "moment-timezone";

function BarrierOpens() {
  const history = useHistory();
  const route = useLocation();

  let auth = useAuth();

  const {t} = useTranslation();

  const [records, setRecords] = useState(false);

  const [query, setQuery] = useState(() => {
    if(route?.state?.query)
    {
      return route?.state?.query;
    }

    return {
      from: momentTimezone().add(-30, 'minute').format('YYYY-MM-DD HH:mm'),

    };
  });


  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query]);

  const {
    handleSubmit,
    register,
    watch,
  } = useForm({
    defaultValues: query
  });

  return (

    <Card className="mx-2 my-2 p-2">
      <Helmet>
        <title>{t('drawer_link_titles.barrier_opens')} - {t('app')}</title>
      </Helmet>

      <Card.Subtitle>
        <Row>
          <Col cols="12" className="d-flex justify-content-between flex-md-row flex-column">
            <h4 className="text-primary">{t('drawer_link_titles.barrier_opens')}</h4>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(setQuery)}>
          <Row className="my-4 gx-2">
            <Col sm={6} className="col-12 d-flex">
              <Form.Control
                type="datetime-local"
                {...register('from')}
                />

              <Form.Control
                type="datetime-local"
                {...register('to')}
                />
            </Col>

            <Col sm={2} xxl={1} className="mt-md-0 mt-3">
              <Button className="w-100" type="submit" value="search" variant="primary">
                {t('search')}
              </Button>
            </Col>

            {records === false && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />
            )  || ''}

          </Row>
        </Form>
      </Card.Subtitle>

      <Table url="/barrier-opens" query={query} showPagination={true} onData={setRecords} />
    </Card>
  )
}

export default BarrierOpens;
