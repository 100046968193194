import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import React, {useMemo} from "react";
import {bearing as getBearing, distance, parseCoordinate} from "../services/Sites";
import {Card} from "react-bootstrap";
import {useAuth} from "../services";

function SiteCard({site, currentLocation}) {
    const {t} = useTranslation();
    const history = useHistory();
    const auth = useAuth();
    const distanceUnits = auth.user?.distance_units ?? 'mi';
    const siteCords = parseCoordinate({lat: site.latitude, lng: site.longitude});
    const bearing = useMemo(
        () => {
            return currentLocation
                && site.latitude
                && site.longitude
                && getBearing(currentLocation, siteCords)
        },
        [currentLocation, site]
    );
    const direction = useMemo(
        () => bearing && Math.round(bearing / 45) % 8,
        [bearing]
    );
    const siteDistance = useMemo(
        () => currentLocation
            && siteCords
            && (distance(currentLocation, siteCords, distanceUnits)).toFixed(2),
        [currentLocation, siteCords, distanceUnits]
    );

    const convertDistance = (distance, unit = 'km') => {
        if(unit === 'km'){
            return distance;
        }

        return (distance * 0.621371).toFixed(2);
    };

    return (
        <Card
            onClick={() => history.push(`/bookings/site-card/${site.id}`)}
            style={{cursor: "pointer"}}
            className="card bg-white text-light small mb-3 p-3 p-xl-3"
        >
            <Card.Body className="p-0 position-relative">
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0 text-primary">{site.name}</h6>
                    {
                        (site.distance || siteDistance) &&
                        <p className="small mb-0 text-black">{`${site.distance ? convertDistance(site.distance, distanceUnits) : siteDistance}${distanceUnits}`}</p>
                    }
                </div>
                <p className="small mt-2 text-black">{site.address}</p>

                <div className="d-flex justify-content-between">
                    <div className="d-inline-flex gap-2 align-items-center">
                        {site.instant_booking && (
                            <div className="bg-primary px-3 py-2 rounded">
                                <h6 className="mb-0 small">{t('dashboard.driver.pay_on_arrival')}</h6>
                            </div>
                        )}
                        {site.prebookable && (
                            <div className="bg-success px-3 py-2 rounded">
                                <h6 className="mb-0 small">
                                    {site.instant_booking ? t('dashboard.driver.prebook') : t('dashboard.driver.prebook_only')}
                                </h6>
                            </div>
                        )}
                    </div>
                    <div className="d-inline-flex gap-2 align-items-center">
                        <p className="text-black m-0">
                            {(() => {
                                switch (direction) {
                                    case 0: return t('north');
                                    case 1: return t('north_west');
                                    case 2: return t('west');
                                    case 3: return t('south_west');
                                    case 4: return t('south');
                                    case 5: return t('south_east');
                                    case 6: return t('east');
                                    case 7: return t('north_east');
                                }
                            })()}
                        </p>
                        <div
                            style={{width: "30px", height: "30px"}}
                            className="align-items-center bg-black d-inline-flex justify-content-center rounded-5 text-white"
                        >
                            <i style={{transform: `rotate(${bearing - 90}deg)`}} className="extra-small bi bi-arrow-right"></i>
                        </div>
                    </div>
                </div>

                {site.spaces_available && (

                    <p className="small mt-2 text-black">
                        {t('access_control.site_spaces_available', { spaces: site.spaces_available } )}
                    </p>
                ) || ''}

            </Card.Body>
        </Card>
    );
}

export default SiteCard;
