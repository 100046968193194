import React, { useEffect, useState } from "react";
import Table from "@/components/Tables/ExitBans";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row, Form, Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "@/services/Auth";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

function ExitBans() {
  const history = useHistory();
  const route = useLocation();

  let auth = useAuth();

  const {t} = useTranslation();

  const [records, setRecords] = useState(false);

  const [query, setQuery] = useState({ ...(route?.state?.query || {}) });

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query]);

  const onSubmit = (values, event, ...props) => {
    if(event.nativeEvent.submitter.value == 'unban')
    {
      const toDelete = [].concat(records);

      setRecords(false);

      Promise.all(
        toDelete.map(record =>
          auth.deleteRequest(`/exit-bans/${record.id}`, values)
            .catch( e => console.error(e))
        )
      )
      .finally(_ => setQuery(values));
    }
    else
    if(event.nativeEvent.submitter.value == 'ban')
    {
      setRecords(false);

      auth.postRequest(`/sites/${auth.user.current_system.id}/exit-bans`, values)
        .catch( e => console.error(e))
        .finally(_ => setQuery(values));
    }
    else
    {
      setQuery(values);
    }
  };

  const {
    handleSubmit,
    register,
    watch,
  } = useForm({
    defaultValues: query
  });

  const search = watch('registration_number');

  let bannable = !(records === false || !search || search != query.registration_number || records.length > 0);

  let unBannable = !(records === false || !search || search != query.registration_number || records.length == 0);

  return (

    <Card className="mx-2 my-2 p-2">
      <Helmet>
        <title>{t('drawer_link_titles.exit_banning')} - {t('app')}</title>
      </Helmet>

      <Card.Subtitle>
        <Row>
          <Col cols="12" className="d-flex justify-content-between flex-md-row flex-column">
            <h4 className="text-primary">{t('drawer_link_titles.exit_banning')}</h4>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="my-4 gx-2">
            <Col sm={4} className="col-12">
              <Form.Control
                type="text"
                {...register('registration_number')}
                placeholder={t('instant_booking.search_registration')}
                />
            </Col>

            <Col sm={2} xxl={1} className="mt-md-0 mt-3">
              <Button disabled={records === false} className="w-100" type="submit" value="search" variant="primary">
                {t('search')}
              </Button>
            </Col>

            <Col sm={2} xxl={1} className={`mt-md-0 mt-3 ${!bannable && 'd-none' || ''}`}>
              <Button disabled={!bannable} className="w-100" type="submit" value="ban" variant="danger">
                {t('Ban')}
              </Button>
            </Col>

            <Col sm={2} xxl={1} className={`mt-md-0 mt-3 ${!unBannable && 'd-none' || ''}`}>
              <Button disabled={!unBannable} className="w-100" type="submit" value="unban" variant="success">
                {t('Unban')}
              </Button>
            </Col>

            {records === false && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />
            )  || ''}

          </Row>
        </Form>
      </Card.Subtitle>

      <Table query={query} showPagination={true} onData={setRecords} />
    </Card>
  )
}

export default ExitBans;
