import React, { useEffect, useState, useRef }   from "react";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/services";
import { CameraIcon } from "@/components/icons";
import { Helmet } from "react-helmet";
import { Form, Button, Card, Table, Row, Col, Nav } from "react-bootstrap";
import LoadingPage                    from "@/components/LoadingPage";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Wysiwyg, { validateWysiwyg } from "@/components/Inputs/Wysiwyg";
import { DisplayWysiwyg, DisplayLocation, DisplayAttachments } from "@/components/Elements";
import LoadingButton from "@/components/LoadingButton";
import Group from "./components/DisplayChecks";
import ShowJourneyReport from './ShowJourneyReport';
import * as csv from 'csv-stringify/sync';
import FileSaver from 'file-saver';
import useMoment from "@/hooks/useMoment";

function ShowReport() {
  const auth = useAuth();
  const {t} = useTranslation();
  const route = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const {momentLocal} = useMoment()

  const requestStore = useRef({});
  const { current: requests } = requestStore;

  const [report, setReport] = useState(null);

  useEffect(() => {
    if(!report || id != report.guid || !report.all)
    {
      requests.report = auth.getRequest('reports/' + id, { include: 'all' })

      requests.report
        .then((response) => {
          setReport(response.data.data);
        })
        .catch(() => console.error(arguments))
    }
  }, [report, id]);

  useEffect(() => {
    return () => {
      for(var key in requests)
      {
          requests[key] && requests[key].abort && requests[key].abort();
      }
    }
  }, []);

  const group = route?.state?.group || null;
  const person = route?.state?.person || null;

  const updateState = (args) => {
    history.push(route.pathname, {
      ...(route.state || {}),

      ...args,
    });
  };

  const exportReport = () => {

    let records = [  ];

    if(report.location)
    {
      records.push([
        t('address_1'),
        report.location.address_1,
      ]);

      records.push([
        t('address_2'),
        report.location.address_2,
      ]);

      records.push([
        t('address_3'),
        report.location.address_3,
      ]);

      records.push([
        t('town_city'),
        report.location.town_city,
      ]);

      records.push([
        t('post_code'),
        report.location.post_code,
      ]);

      records.push([
        t('county'),
        report.location.county,
      ]);

      records.push([
        t('country'),
        report.location.country,
      ]);
    }

    if(report.detail)
    {
      if(report.detail.type)
      {
        records.push([
          t(`compliance.${report.type}.type`),
          t(`compliance.${report.type}.types.` + report.detail.type)
        ]);
      }

      if(report.detail.description)
      {
        records.push([
          t(`compliance.${report.type}.description`),
          report.detail.description.replace(/(<([^>]+)>)/gi, ""),
        ]);
      }

      if(report.detail.notes)
      {
        records.push([
          t(`compliance.${report.type}.notes`),
          report.detail.notes.replace(/(<([^>]+)>)/gi, ""),
        ]);
      }

      if(report.detail.cargo)
      {
        records.push([
          t(`compliance.${report.type}.cargo`),
          report.detail.cargo.replace(/(<([^>]+)>)/gi, ""),
        ]);
      }

      if(report.detail.driver_injuries)
      {
        records.push([
          t(`compliance.${report.type}.driver_injuries`),
          report.detail.driver_injuries.replace(/(<([^>]+)>)/gi, ""),
        ]);
      }

      if(report.detail.vehicle_damage)
      {
        records.push([
          t(`compliance.${report.type}.vehicle_damage`),
          report.detail.vehicle_damage.replace(/(<([^>]+)>)/gi, ""),
        ]);
      }
    }


    if(report.police_report)
    {
      records.push([
        t(`compliance.CrimeReport.filed_with_police`),
        report.police_report.filed_with_police ?  t('yes') : t('no'),
      ]);


      if(report.police_report.police_branch_name)
      {
        records.push([
          t(`compliance.CrimeReport.police_branch_name`),
          report.police_report.police_branch_name,
        ]);
      }

      if(report.police_report.police_reference)
      {
        records.push([
          t(`compliance.CrimeReport.police_reference`),
          report.police_report.police_reference,
        ]);
      }

      if(report.police_report.officer_name)
      {
        records.push([
          t(`compliance.CrimeReport.officer_name`),
          report.police_report.officer_name,
        ]);
      }

      if(report.police_report.police_contact_number)
      {
        records.push([
          t(`compliance.CrimeReport.police_contact_number`),
          report.police_report.police_contact_number,
        ]);
      }

      if(report.police_report.follow_up_actions)
      {
        records.push([
          t(`compliance.CrimeReport.follow_up_actions`),
          report.police_report.follow_up_actions.replace(/(<([^>]+)>)/gi, ""),
        ]);
      }

    }

    if(report?.persons?.length > 0)
    {
      let headers = [
        t('type'),
        t('name'),
        t('email_address'),
        t('phone_number'),
        t('role'),
        t('registration'),
        t('compliance.CrimeReport.description'),
        t('address_1'),
        t('address_2'),
        t('address_3'),
        t('town_city'),
        t('post_code'),
        t('county'),
        t('country'),
      ];

      records.push([]);
      records.push(headers);

      report.persons.forEach(person => {
        records.push([
          person.type,
          person.name,
          person.email,
          person.phone,
          person.role,
          person.registration,
          person.description && person.description.replace(/(<([^>]+)>)/gi, ""),
          person?.location?.address_1,
          person?.location?.address_2,
          person?.location?.address_3,
          person?.location?.town_city,
          person?.location?.postcode,
          person?.location?.county,
          person?.location?.country,
        ]);
      })
    }

    if(report?.groups?.length > 0 || report?.checks?.length > 0)
    {
      let headers = [
        t('type'),
        t('compliance.check_key_name'),
        t('compliance.severity_label'),
        t('compliance.answer'),
        t('compliance.details'),
      ];

      records.push([]);
      records.push(headers);

      function exportGroup(group, type)
      {
        type = type || [];

        if(group?.checks?.length > 0)
        group.checks.forEach(check => {
          records.push([
            type.map(key => t(key)).join(' > '),
            t(check.key),
            check.severity && t(`compliance.severity.${check.severity}`),
            check.value && [1, 0, -1, '1', '0', '-1'].includes(check.value) && (check.value == "-1" ? t('na') : (check.value == "0" ? t('no') : t('yes'))) || t(check.value),
            check.content && check.content.replace(/(<([^>]+)>)/gi, ""),
          ]);
        })

        if(group?.groups?.length > 0)
        group.groups.forEach(group => exportGroup(group, [].concat(type).concat([group.key])))
      }

      exportGroup(report);
    }



    const data = "\uFEFF" + csv.stringify(records, {});
    const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

    FileSaver.saveAs(blob, `${report?.user?.name || report.user_id == auth.user.id && auth.user.name} - ${t('drawer_link_titles.compliance')} - ${t(`compliance.${report.type}.title`)}.csv`, {});
  }


  if(!report)
  {
    return <LoadingPage />;
  }

  if(report.type === 'JourneyReport')
  {
    return <ShowJourneyReport {...{ report, setReport }} />;
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>{t(`compliance.${report.type}.title`)} - {report?.vehicle?.registration_number || ''} - {t('app')}</title>
      </Helmet>

      <Nav>
        <h1 className="h2">
          {report?.user?.name || report.user_id == auth.user.id && auth.user.name} - {t('drawer_link_titles.compliance')} - {t(`compliance.${report.type}.title`)}
        </h1>

        <div className="d-flex flex-md-row flex-column mt-3 mt-lg-none ms-md-auto d-print-none">
          <Button variant="primary" className="mx-1 my-1 my-md-0" onClick={_ => window.print()}>{t('print')}</Button>
          <Button variant="success" className="mx-1 my-1 my-md-0" onClick={exportReport}>{t('export')} (.csv)</Button>
        </div>

      </Nav>

      <h2 className="h4">{momentLocal(report.occurance_at).format(t('formats.date'))} - {report?.vehicle?.registration_number || ''}</h2>

      <Row>


        <Col xl={3} lg={4} className="order-lg-2">
          {auth?.roles?.update_compliance_report && (
            <UpdateReport {... { report, setReport }} />
          ) || ''}

          {report.user && (
            <>
              <Form.Group className="mb-3">
                <label htmlFor="name" className="my-2 "><strong>{t('name')}</strong></label>

                {report.user.name}
              </Form.Group>

              <Form.Group className="mb-3">
                <label htmlFor="email" className="my-2 "><strong>{t('email')}</strong></label>

                {report.user.email}
              </Form.Group>

              <Form.Group className="mb-3">
                <label htmlFor="role" className="my-2 "><strong>{t('role')}</strong></label>

                {report.user.user_type}
              </Form.Group>

              <Form.Group className="mb-3">
                <label htmlFor="datetime" className="my-2 "><strong>{t('created')}</strong></label>

                {momentLocal(report.created_at).format(t('formats.datetime'))}
              </Form.Group>


            </>

          ) || ''}
        </Col>
        <Col xl={9} lg={8}>
          <div className={group || person ? 'd-none d-print-block' : ''}>
          {(report.detail || report.location || report.police_report) && (
            <Row>
              {report.location && (
              <Col md={4} className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t('location')}</strong></label>

                    <DisplayLocation location={report.location} />

              </Col>
              ) || ''}

              <Col md={4}>
                {report?.detail?.type && (
                  <Form.Group className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t(`compliance.${report.type}.type`)}</strong></label>

                    {t(`compliance.${report.type}.types.` + report.detail.type)}
                  </Form.Group>

                ) || ''}

                {report?.detail?.description && (
                  <Form.Group className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t(`compliance.${report.type}.description`)}</strong></label>

                    <DisplayWysiwyg html={report.detail.description} />
                  </Form.Group>

                ) || ''}

                {report?.detail?.notes && (
                  <Form.Group className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t(`compliance.${report.type}.notes`)}</strong></label>

                    <DisplayWysiwyg html={report.detail.notes} />
                  </Form.Group>

                ) || ''}

                {report?.detail?.cargo && (
                  <Form.Group className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t(`compliance.${report.type}.cargo`)}</strong></label>

                    <DisplayWysiwyg html={report.detail.cargo} />
                  </Form.Group>

                ) || ''}


                {report?.detail?.attachments && (
                  <Form.Group className="mb-3">
                    <DisplayAttachments preview={true} attachments={report.detail.attachments} />
                  </Form.Group>

                ) || ''}


                {(report?.detail?.driver_injuries || typeof report?.detail?.driver_injuried != 'undefined') && (
                  <Form.Group className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t(`compliance.${report.type}.driver_injuries`)}</strong></label>
                    <p>{report.detail.driver_injuried && t('yes') || t('no')}</p>

                    <DisplayWysiwyg html={report.detail.driver_injuries} />
                  </Form.Group>
                ) || ''}

                {report?.detail?.vehicle_damage && (
                  <Form.Group className="mb-3">
                    <label htmlFor="type" className="my-2 "><strong>{t(`compliance.${report.type}.vehicle_damage`)}</strong></label>

                    <DisplayWysiwyg html={report.detail.vehicle_damage} />
                  </Form.Group>

                ) || ''}

              </Col>

              <Col md={4}>
                {typeof report?.police_report?.filed_with_police != 'undefined' && (
                  <Form.Group className="mb-3">
                    <label htmlFor="filed_with_police" className="my-2 "><strong>{t('compliance.CrimeReport.filed_with_police')}</strong></label>

                    {report.police_report.filed_with_police ? t('yes') : t('no')}
                  </Form.Group>
                ) || ''}

                {report?.police_report?.police_branch_name && (
                  <Form.Group className="mb-3">
                    <label htmlFor="police_branch_name" className="my-2 "><strong>{t('compliance.CrimeReport.police_branch_name')}</strong></label>

                    {report.police_report.police_branch_name}
                  </Form.Group>
                ) || ''}

                {report?.police_report?.police_reference && (
                  <Form.Group className="mb-3">
                    <label htmlFor="police_reference" className="my-2 "><strong>{t('compliance.CrimeReport.police_reference')}</strong></label>

                    {report.police_report.police_reference}
                  </Form.Group>
                ) || ''}

                {report?.police_report?.officer_name && (
                  <Form.Group className="mb-3">
                    <label htmlFor="officer_name" className="my-2 "><strong>{t('compliance.CrimeReport.officer_name')}</strong></label>

                    {report.police_report.officer_name}
                  </Form.Group>
                ) || ''}

                {report?.police_report?.police_contact_number && (
                  <Form.Group className="mb-3">
                    <label htmlFor="police_contact_number" className="my-2 "><strong>{t('compliance.CrimeReport.police_contact_number')}</strong></label>

                    {report.police_report.police_contact_number}
                  </Form.Group>
                ) || ''}

                {report?.police_report?.follow_up_actions && (
                  <Form.Group className="mb-3">
                    <label htmlFor="follow_up_actions" className="my-2 "><strong>{t('compliance.CrimeReport.follow_up_actions')}</strong></label>

                    <DisplayWysiwyg html={report.police_report.follow_up_actions} />
                  </Form.Group>
                ) || ''}
              </Col>
            </Row>

          ) || ''}
          </div>

          {report?.persons?.length > 0 && (
            <Persons {...{ persons: report.persons, setPerson: person => updateState({ person }), person, show: !(group || person) }} />
          )}

          {report?.groups?.length > 0 && (
            <Groups {...{ groups: report.groups, setGroup: group => updateState({ group }), group, show: !(group || person) }} />
          )}

          {report?.checks?.length > 0 && (
            <Group {...report} />
          )}

        </Col>
      </Row>
    </div>
  );
}

export default ShowReport;


function Person({ person, setPerson, ...props })
{
  const { t } = useTranslation();

  return (
    <div {...props}>
      {person.name && (
        <Form.Group className="mb-3">
          <label htmlFor="name" className="my-2 "><strong>{t('name')}</strong></label>

          {person.name}
        </Form.Group>
      ) || ''}

      {person.type && (
        <Form.Group className="mb-3">
          <label htmlFor="type" className="my-2 "><strong>{t('type')}</strong></label>

          {person.type}
        </Form.Group>
      ) || ''}

      {person.role && (
        <Form.Group className="mb-3">
          <label htmlFor="role" className="my-2 "><strong>{t('role')}</strong></label>

          {person.role}
        </Form.Group>
      ) || ''}

      {person.email && (
        <Form.Group className="mb-3">
          <label htmlFor="email" className="my-2 "><strong>{t('email_address')}</strong></label>

          {person.email}
        </Form.Group>
      ) || ''}

      {person.phone && (
        <Form.Group className="mb-3">
          <label htmlFor="phone" className="my-2 "><strong>{t('phone_number')}</strong></label>

          {person.phone}
        </Form.Group>
      ) || ''}

      {person.registration && (
        <Form.Group className="mb-3">
          <label htmlFor="registration" className="my-2 "><strong>{t('registration')}</strong></label>

          {person.registration}
        </Form.Group>
      ) || ''}

      {person.location && (
        <Form.Group className="mb-3">
          <label htmlFor="type" className="my-2 "><strong>{t('location')}</strong></label>

          <DisplayLocation location={person.location} />
        </Form.Group>
      ) || ''}


      {person.description && (
        <Form.Group className="mb-3">
          <label htmlFor="description" className="my-2 "><strong>{t('compliance.CrimeReport.description')}</strong></label>

          <DisplayWysiwyg html={person.description} />
        </Form.Group>
      ) || ''}
    </div>
  );
}


function Persons({ persons, setPerson, show, person: selected })
{
  const {t} = useTranslation();

  return (
    <>
    <Table responsive className={`d-print-none ${show ? '' : 'd-none'}`}>
      <thead>
        <tr>
          <th>{t('type')}</th>
          <th>{t('name')}</th>
          <th>{t('email_address')}</th>
          <th>{t('phone_number')}</th>
        </tr>
      </thead>

      <tbody>
        {persons.map((person, index) => (
          <tr key={index}>
            <td>
              {person.type}
            </td>

            <td>
              <a href="javascript:void(0);" onClick={_ => setPerson(person)}>
                {person.name}
              </a>
            </td>

            <td>
              {person.email}
            </td>

            <td>
              {person.phone}
            </td>
          </tr>

        ))}
      </tbody>
    </Table>


    {persons.map((person, index) => (
      <Person key={index} {...{ person, className: selected?.id === person.id ? '' : 'd-none d-print-block' }} />
    ))}

    </>
  );
}


function Groups({ groups, setGroup, group: selected, show })
{
  const {t} = useTranslation();

  const {momentLocal} = useMoment()

  function displayGroup(group)
  {
    return group?.groups?.length > 0 && (
      <>
        <tr key={group.key}>
          <td colSpan="100%">
            <h3>{t(group.key)}</h3>
          </td>
        </tr>
        {group.groups.map(displayGroup)}
      </>
    ) || (

        <tr key={group.key} className={group.severity == 'Severe' && 'table-danger' || group.severity == 'Fault' && 'table-warning'}>
          <td>
            <a href="javascript:void(0);" onClick={_ => setGroup(group)}>{t(group.key)}</a>
          </td>

          <td>
            {t(group.severity)}
          </td>

          <td>
            {group.created_at && momentLocal(group.created_at).format(t('formats.datetime'))}
          </td>
        </tr>
    );
  }

  function printGroup(group)
  {
    return group?.groups?.length > 0 && (
      <>
        <h3 className="d-none d-print-block">{t(group.key)}</h3>

        {group.groups.map(printGroup)}
      </>
    ) || (
      <div className="print-break-inside-avoid">
        <h4 className="d-none d-print-block">{t(group.key)}</h4>

        <p  className="d-none d-print-block">
          {t(group.severity)}
          <br />
          {group.created_at && momentLocal(group.created_at).format(t('formats.datetime'))}
        </p>

        <Group {...{ ...group, className: group.key === selected?.key ? '' : 'd-print-block d-none' }} />
      </div>
    );
  }

  return (
    <>

    <Table responsive className={`d-print-none ${show ? '' : 'd-none'}`}>
      <thead>
        <tr>
          <th>{t('compliance.DailyReport.group_key_name')}</th>
          <th>{t('status')}</th>
          <th>{t('date_time_completed')}</th>
        </tr>
      </thead>

      <tbody>
        {groups.map(displayGroup)}
      </tbody>
    </Table>

    {groups.map(printGroup)}

    </>
  );

}


function UpdateReport({ report, setReport })
{
  const auth = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    notes: yup.string()
      .test(
        'max-content',
        t('form_validation.max_length', { attribute: t('comments'), max: 65000 }),
        validateWysiwyg
      ),
    status: yup.string()
        .required(t('form_validation.is_required', { attribute: t('status') })),
  });


  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: (() => {
      const values = { status: report.status, notes: report.notes };

      return values;
    })(),
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    auth.putRequest('reports/' + report.guid, data)
        .then(response => {
          setReport(response.data.data);
        })
        .finally(_ => setLoading(false));
  };


  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="d-print-none">
      <Form.Group className="mb-3">
        <label htmlFor="status" className="my-2">{t('status')}</label>
        <Form.Select
          {...register('status')}
          isInvalid={!!errors?.status}
        >

            <option value="">--{t('choose')}--</option>

            <option value="Incomplete">{t('compliance.report_status.Incomplete')}</option>
            <option value="Pending">{t('compliance.report_status.Pending')}</option>
            <option value="Waiting">{t('compliance.report_status.Waiting')}</option>
            <option value="Resolved">{t('compliance.report_status.Resolved')}</option>
            <option value="Complete">{t('compliance.report_status.Complete')}</option>
        </Form.Select>

        <Form.Control.Feedback status="invalid">
          {errors?.status && errors?.status.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="notes" className="my-2">{t('comments')}</label>
        <Wysiwyg
          {...register('notes')}
          blockEmbeds={false}
          uploadText={<><CameraIcon  classDefined="me-2" />{t('upload_a_photo')} </>}
          toolbar={false}
        />

        <Form.Control.Feedback type="invalid">
          {errors?.notes && errors?.notes.message}
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group className="mb-3">
        <LoadingButton loading={loading} className="w-100" variant="success" type="submit" titleTranslationKey="save" />
      </Form.Group >
    </Form>
  );
}

