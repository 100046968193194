import React, { useEffect, useState, useRef }   from "react";
import { Link, useParams }              from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import * as moment                    from "moment";
import { useTranslation }               from "react-i18next";
import { useAuth }                      from "@/services/Auth";
import Paginate from "../Paginate";
import useMoment from "../../hooks/useMoment";

function useColumns(filter, isExport) {
  const auth = useAuth();
  const {t} = useTranslation();

  const {momentLocal} = useMoment()

  let columns = [
    {
      dataField: 'registration_number',
      text     : t('vehicle_registration_number'),
    },
    auth.roles.view_vehicles && {
      dataField: 'vehicle',
      text     : t('vehicle'),
      formatter: vehicle => vehicle && <Link to={`/vehicles/${vehicle.id}`}>{vehicle.registration_number}</Link>,
      exportFormatter: vehicle => vehicle?.registration_number,
    } || '',
    {
      dataField: 'created_at',
      text: t('compliance.datetime'),
      formatter: value => momentLocal(value).format(t('formats.datetime')),
    },
    !auth.user?.current_system?.exit_banning && {
      dataField: 'site',
      text     : t('site_name'),
      formatter: (cell, row) => {
          if (!auth.user.is_client_admin && !auth.user.is_fleet_manager) {
              return cell && <Link to={`/sites/${cell.id}`}>{cell.name}</Link>
          } else if (auth.user.is_client_operator) {
              return cell && <span>{cell.name}</span>
          }

          return cell && <Link to={`/bookings/site-card/${cell.id}`}>{cell.name}</Link>
      },
      exportFormatter: (cell) => cell?.name,
    } || '',

  ].filter(_ => !!_);

  return filter && columns.filter(filter) || columns;
}

function ExitBansTable({ data: recordsProp, paginate, url, query, ...props }) {
  let auth = useAuth();
  const {t} = useTranslation();

  const perPage = props.perPage || 20;
  const exclude = props.exclude || [];
  const showPagination = paginate == null ? true : paginate;

  const request = useRef(null);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotal] = useState(false);
  const [recordsDownloaded, setRecords] = useState(false);

  const getRecords = () => {
    request?.current?.abort && request.current.abort();

    const critera = { ... (query || {})};

    critera.take = perPage;
    critera.skip = page * perPage;
    critera.include = '0';

    if(auth.roles.view_vehicles)
    {
      critera.include += ',vehicle';
    }

    request.current = auth.getRequest(url || '/exit-bans', critera);

    setRecords(false);

    request.current
      .then(response => {
        setRecords(response.data.data);
        setTotal(response.data.length);
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

  const changePage = ({ selected }) => {
    setPage(selected)
  }

  const records = recordsProp || recordsDownloaded;

  useEffect(() => {
    if(!recordsProp)
    {
      getRecords();
    }

    return () => request?.current?.abort && request.current.abort();
  }, [page, query, url]);

  useEffect(() => {
    props.onData && props.onData(records);
  }, [records]);

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords);
  }, [totalRecords]);

  return (
    <>
      <BootstrapTable
        keyField="id"
        striped
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{ changePage, totalRecords, page, perPage }} />
        || ''
      }
    </>
  );
}

export default ExitBansTable;

export {
  useColumns
};
