import {Modal}  from "react-bootstrap";
import React, {useEffect, useState, useRef}   from "react";

function CellImage({ image }) {
    const [open, setOpen] = useState(false);

    if(! image?.url)
    {
        return <></>;
    }

    return (
        <>
        <button type="button" className="btn btn-link p-0" onClick={_ => setOpen(true)}>
            <img src={image.url} width="100" />
        </button>

        <Modal show={open} size="lg" onHide={_ => setOpen(false)}>
            <Modal.Header closeButton />

            <Modal.Body>
                <img src={image.url} width="100%" />
            </Modal.Body>
        </Modal>

        </>
    )
}

export default CellImage;
