import React, {useEffect, useState, useRef}   from "react";
import {Link}                         from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import * as moment                    from "moment-timezone";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";
import CellImage from "@/components/CellImage";
import useMoment from "../../hooks/useMoment";

function useColumns(filter, isExport = false) {
    const {t} = useTranslation();

    const {momentLocal} = useMoment()

    let columns = [
        {
            dataField: 'created_at',
            text     : t('access_control.barrier_opened_at'),
            formatter: date => date && momentLocal(date).format(t('formats.datetime')),
        },
        {
          dataField: 'registration_scan.registration_number',
          text     : t('vehicle_registration_number'),
        },
        {
          dataField: 'registration_scan.direction',
          text     : t('access_control.registation_scan_direction'),
        },
        {
            dataField: 'registration_scan.image',
            text     : t('access_control.camera_image'),
            formatter: (cell, row) => cell && <CellImage image={cell} /> || '',
        },
        {
          dataField: 'registration_scan.status',
          text     : t('access_control.registation_scan_status'),
        },
    ];

    return filter && columns.filter(filter) || columns;
}


function BarrierOpens({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;

        critera.include = [];

        columns.find(_ => _.dataField == 'registration_scan.image') && critera.include.push('registration_scan.image');

        request.current = auth.getRequest(url, critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.data);
                setTotal(response.data.length);
            })
            .catch(error => {})
    }


    const changePage =(event) => {
        setPage(event.selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default BarrierOpens;

export {
    useColumns
};
