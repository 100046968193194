import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {Link, useParams} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import LoadingButton from "@/components/LoadingButton";
import LoadingPage from "@/components/LoadingPage";
import Select from "react-select";
import { Helmet } from "react-helmet";
import * as csv from "csv-stringify/sync";
import FileSaver from 'file-saver';
import ImportUploadForm from "@/components/Forms/ImportUploadForm";

function ImportUsers() {
    const {t} = useTranslation();

    let auth = useAuth();

    let {id, type} = useParams();

    const [success, setSuccess] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [importSchema, setImportSchema] = useState(null);


    const createImportUrl = () => {
        let url = '/users/import';

        switch(type)
        {
            case 'clients':
                url = `/clients/${id}` + url;
                break;

            case 'fleets':
                url = `/fleets/${id}` + url;
                break;

            case 'suppliers':
                url = `/suppliers/${id}` + url;
                break;

            case 'sites':
                url = `/sites/${id}` + url;
                break;
        }

        return url;
    }


    const downloadSampleFile = () => {
        setDownloadingFile(true);
        auth.getRequest('users/import-sample')
            .then(response => {
                const records = response.data.sample_records;
                const headers = response.data.headers.map(column => t(column));

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'users.csv', {});
                setDownloadingFile(false);
            })
            .catch(error => {
                setDownloadingFile(false);
            })
    }

    useEffect(() => {
        if(importSchema === null)
        {
            let request = auth.getRequest(createImportUrl());

            request
                .then(response => {
                    setImportSchema(Object.keys(response.data.schema));
                })
                .catch(error => {
                })

            return () => request?.abort && request.abort();

        }
    }, [importSchema]);


    return (
        <div>
            {
                success &&
                <div className="d-flex justify-content-center align-items-center" style={{minHeight: 400}}>
                    <Card border="light">
                        <Card.Body className="text-center">
                            <i className="bi bi-check-circle" style={{fontSize: "10rem"}}/>
                            <Card.Title>{t('users_imported')}</Card.Title>
                            <Card.Text>
                                {t('all_users_are_imported')}
                            </Card.Text>
                            <div className="d-grid gap-2">
                                <Link variant="primary" className="btn btn-primary" to={`/users`}>{t('go_to_users')}</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                !success &&
                <Card className="mx-2 my-2 p-2">
                    <Helmet>
                        <title>{t('import')} / {t('drawer_link_titles.users')} - {t('app')}</title>
                    </Helmet>

                    <Card.Body>
                        <Row>
                            <Col className="col-12 mb-4">
                                <h5>{t('choose_csv_file_to_upload')}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 mb-4 d-flex justify-content-between">
                                <h6>{t('csv_file_should_contain_the_following_columns')}</h6>
                                <LoadingButton loading={downloadingFile} variant="success" titleTranslationKey="download_sample_file" onSubmit={() => downloadSampleFile()}/>
                            </Col>
                            <Col className="col-12 mb-4">
                                {
                                    importSchema && (<Table striped bordered hover className="mt-1">
                                    <thead>
                                    <tr>
                                        <th>{t('column')}</th>
                                        <th>{t('example')}</th>
                                        <th>{t('notes')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        importSchema.includes('first_name') &&
                                        <tr>
                                            <td>{t('first_name')}</td>
                                            <td>John</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('last_name') &&
                                        <tr>
                                            <td>{t('last_name')}</td>
                                            <td>Doe</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('phone_number') &&
                                      <>
                                        <tr>
                                            <td>{t('country_code')}</td>
                                            <td>+44</td>
                                            <td>Required</td>
                                        </tr>
                                        <tr>
                                            <td>{t('phone_number')}</td>
                                            <td>(049) 63648018</td>
                                            <td>Required</td>
                                        </tr>
                                      </>
                                    }
                                    {
                                        importSchema.includes('email_address') &&
                                        <tr>
                                            <td>{t('email_address')}</td>
                                            <td>john.doe@email.com</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                      importSchema.includes('preferred_notification_channel') &&
                                      <tr>
                                        <td>{t('preferred_notification_channel')}</td>
                                        <td>Email</td>
                                        <td>
                                          {t('options')}
                                          <ul>
                                            <li>{t('notification_channels.email')}</li>
                                            <li>{t('notification_channels.sms')}</li>
                                            <li>{t('notification_channels.email_and_sms')}</li>
                                          </ul>
                                        </td>
                                      </tr>
                                    }
                                    {
                                        importSchema.includes('account_number') &&
                                        <tr>
                                            <td>{t('account_number')}</td>
                                            <td>123456778</td>
                                            <td>Required</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('account_status') &&
                                        <tr>
                                            <td>{t('account_status')}</td>
                                            <td>Active</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    <li>{t('active')}</li>
                                                    <li>{t('stopped')}</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('account_type') &&
                                        <tr>
                                            <td>{t('account_type')}</td>
                                            <td>{t('roles.platform_admin')}</td>
                                            <td>
                                                {t('options')}
                                                <ul>
                                                    {auth.user.is_platform_admin &&
                                                        <li>{t('roles.platform_admin')}</li>}
                                                    {auth.user.is_platform_admin &&
                                                        <li>{t('roles.supplier_admin')}</li>}
                                                    {auth.user.is_platform_admin && <li>{t('roles.client_admin')}</li>}
                                                    {(auth.user.is_platform_admin || auth.user.is_supplier_admin) &&
                                                        <li>{t('roles.site_manager')}</li>}
                                                    {(auth.user.is_platform_admin || auth.user.is_client_admin) &&
                                                        <li>{t('roles.fleet_manager')}</li>}
                                                    {(auth.user.is_platform_admin || auth.user.is_supplier_admin || auth.user.is_site_manager) &&
                                                        <li>{t('roles.operator')}</li>}
                                                    {(auth.user.is_platform_admin || auth.user.is_client_admin || auth.user.is_fleet_manager) &&
                                                        <li>{t('roles.driver')}</li>}
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        (!auth.user.is_supplier_admin && importSchema.includes('vehicles')) &&
                                        <tr>
                                            <td>{t('vehicles')}</td>
                                            <td>GL18 DHB, BD 22 SGQ</td>
                                            <td>{t('list_of_vehicles_registration_numbers_separated_by_comma')}</td>
                                        </tr>
                                    }
                                    {
                                        (!auth.user.is_supplier_admin && importSchema.includes('fleet_name')) &&
                                        <tr>
                                            <td>{t('fleet_name')}</td>
                                            <td>Fleet Name</td>
                                            <td>{t('valid_fleet_name_that_is_created_for_this_driver_supplier')}</td>
                                        </tr>
                                    }
                                    {
                                        (!auth.user.is_client_admin && importSchema.includes('sites')) &&
                                        <tr>
                                            <td>{t('sites')}</td>
                                            <td>Site Name</td>
                                            <td>{t('list_of_site_names_separated_by_comma')}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>)
                                    || <LoadingPage/>
                                }
                            </Col>

                        </Row>
                        <ImportUploadForm
                            importSchema={importSchema}
                            endpoint={createImportUrl()}
                            onSuccess={()=>{setSuccess(true)}}
                        ></ImportUploadForm>
                    </Card.Body>
                </Card>
            }
        </div>
    )
}

export default ImportUsers;
